const loginTypes = {
  EXPLICIT: 'explicit',
  DEFAULT: 'default',
  SELL: 'sell',
  QUESTION: 'question',
  QUOTE: 'quote',
  CONTACT: 'contact',
  FAVORITE: 'favorite',
  SHARE: 'share',
  PRINT: 'print',
  PUBLISH: 'publish',
  DOWNLOAD: 'download',
  BUY: 'buy',
  ADD_CART: 'add_cart',
  VIEW_CART: 'view_cart',
  CHECKOUT_BUY: 'checkout_buy',
  CHECKOUT_AUCTION: 'checkout_auction',
  ADVANCE_REAL_STATE: 'advance_real_state',
  ADVANCE_SERVICES: 'advance_services',
  ADVANCE_MOTORS: 'advance_motors',
  CALLME_REAL_STATE: 'callme_real_state',
  POINT_REDEEM_COUPON: 'point_redeem_coupon',
  GOOGLE_PLAY: 'google_play',
  BUY_MS_STORE: 'buy_ms_store',
  MSHOPS_HIGH_RISK: 'mshops_high_risk',
  CONSUMER_CREDITS: 'consumer_credits',
  NEGATIVE_TRAFFIC: 'negative_traffic',
  CBT: 'cbt',
  POINT_BUYING_FLOW: 'point_buy',
  REGISTRATION_V3: 'registration_v3',
  REGISTRATION_V0: 'registration_v0',
  REGISTRATION_V0_LEGACY: 'registration_v0_legacy',
  REGISTRATION_V0_PJ: 'registration_v0_pj',
  REGISTRATION_RECOVERY_V0: 'registration_recovery_v0',
  REGISTRATION_RECOVERY_V3: 'registration_recovery_v3',
  VIS_MOTOR_CONTACT: 'vis_motor_contact',
  VIS_CREDITS_SIMULATION: 'vis_credits_simulation',
  FAQS: 'faqs',
  DIGITAL_SUS: 'digital_sus',
  REGISTRATION_LINK: 'registration_link',
  IFPE: 'ifpe',
  CHECKOUT_PRO: 'checkout_pro',
  GUEST_LOGIN_ML: 'guest_login_ml',
  GUEST_LOGIN_MP: 'guest_login_mp',
  REGISTRATION_FASTTRACK: 'registration_fasttrack',
  REGISTRATION_FASTTRACK_OFF: 'registration_fasttrack_off',
  FRAUD_REMEDIES: 'fraud_remedies',
  HYBRID_GUEST: 'hybrid_guest',
  LOGIN_CHOICE: 'login_choice',
  SCP_WALLET: 'scp_wallet',
  WALLET_CONNECT: 'wallet_connect',
  MERCADO_PLAY: 'mercado_play',
  CFO: 'cfo',
  SCP_CHECKOUT_OFF: 'scp_checkout_off',
  ASSISTED_HELP: 'assisted_help',
};

const SITES = {
  CBT: 'CBT',
};

const PLATFORM = {
  ML: 'ML',
  MP: 'MP',
  MS: 'MS',
};

const SHOPS = {
  IDENTIFIER: 'MSHOPS',
  MELIDATA: {
    BUSINESS: 'mercadoshops',
  },
  GA: {
    BUSINESS: 'SHOPS',
  },
  SECTION: {
    LOGIN: 'login',
    NO_INDEX: 'noindex',
  },
};

const tagContext = {
  CARD: 'card_title',
  DOCUMENT: 'document_title',
};

const AuthFlow = {
  REAUTH: 'reauth',
  LOGIN: 'login',
};

const ErrorCodes = {
  EMAIL_NOT_FOUND: 'email_not_found',
  NICKNAME_NOT_FOUND: 'nickname_not_found',
  PHONE_NOT_FOUND: 'phone_not_found',
  MISSING_RESPONSE: 'missing_response',
  USER_REQUIRED: 'user_required',
  USER_INCOMPLETE_REGISTRATION: 'user_incomplete_registration',
  PASSWORD_INVALID: 'password_invalid',
  PASSWORD_REQUIRED: 'password_required',
  CAPTCHA_WORD_REQUIRED: 'captcha_word_required',
  CAPTCHA_RESPONSE_INVALID: 'captcha_response_invalid',
  INVALID_USER_SITE: 'invalid_user_site',
  USER_SITE_PLATFORM_MISMATCH: 'user_site_platform_mismatch',
  OPERATOR_NOT_ALLOWED: 'operator_not_allowed',
  SERVER_ERROR: 'server_error',
  EMAIL_INVALID: 'email_invalid',
  EMAIL_MISMATCH: 'email_mismatch',
  RECAPTCHA: 'recaptcha',
};

const DPSection = {
  OTP: 'LoginOTP',
  LOGIN: 'LoginGZ',
};

const FURY_SCOPES = {
  PRODUCTION: 'production',
  REAUTH: 'reauth-production',
};

const PRODUCTIVE_SCOPES = [FURY_SCOPES.PRODUCTION, FURY_SCOPES.REAUTH];


const VIEW_MODES = {
  landscape: 'landscape',
};

const FaceValidationVersionSupported = {
  ML: '10.219.0',
  MP: '2.229.0',
};

const OS = {
  Android: 'Android',
  iOS: 'iOS',
};

const MinVersionSupportedForWK2 = {
  Webview: { [OS.Android]: '66.0.0' },
};

const appInStoreDeeplink = {
  Android: {
    ML: 'https://play.google.com/store/apps/details?id=com.mercadolibre',
    MP: 'https://play.google.com/store/apps/details?id=com.mercadopago.wallet',
  },
  iOS: {
    webView: {
      ML: 'itms-apps://itunes.apple.com/app/apple-store/id463624852?mt=8',
      MP: 'itms-apps://itunes.apple.com/app/apple-store/id925436649?mt=8',
    },
    browser: {
      ML: 'https://apps.apple.com/co/app/mercado-libre/id463624852',
      MP: 'https://apps.apple.com/co/app/mercado-pago/id925436649',
    },
  },
};

const CHALLENGES = {
  password: 'enter_password',
  recaptcha: 'recaptcha',
  email_or_nickname: 'email_or_nickname',
  email_or_nickname_or_phone: 'email_or_nickname_or_phone',
  email_or_nickname_or_phone_or_social_login: 'email_or_nickname_or_phone_or_social_login',
  email_or_nickname_or_phone_or_social_login_with_recaptcha:
    'email_or_nickname_or_phone_or_social_login_with_recaptcha',
};

const SecurityProblemReportOrigin = {
  IDENTIFICATION: 'LOGIN_IDENTIFICATION_WEB',
  PASSWORD: 'LOGIN_PASSWORD_WEB',
};

const LoginVersion = {
  v2: '2',
};
const WebKitV2 = '2.0.0';

const DeeplinksSchemas = {
  ML: 'meli:',
  MP: 'mercadopago:',
};

const SessionType = {
  STANDARD: 'standard',
  SCOPED: 'scoped',
};

const SessionName = {
  SSID: 'ssid',
  SCP_WALLET: 'scp_wallet',
};

const HttpStatusCodes = {
  INVALID_STATE: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
};

const INVALID_SSID_MESSAGE = 'Invalid ssid';

const socialTypes = {
  social_login: 'social_login',
};

const ScopedLoginTypes = [
  loginTypes.SCP_WALLET,
  loginTypes.SCP_CHECKOUT_OFF,
];

module.exports = {
  loginTypes,
  LoginVersion,
  tagContext,
  AuthFlow,
  PLATFORM,
  SHOPS,
  SITES,
  ErrorCodes,
  DPSection,
  FURY_SCOPES,
  PRODUCTIVE_SCOPES,
  VIEW_MODES,
  FaceValidationVersionSupported,
  OS,
  appInStoreDeeplink,
  CHALLENGES,
  SecurityProblemReportOrigin,
  WebKitV2,
  DeeplinksSchemas,
  SessionType,
  SessionName,
  MinVersionSupportedForWK2,
  HttpStatusCodes,
  INVALID_SSID_MESSAGE,
  socialTypes,
  ScopedLoginTypes,
};
